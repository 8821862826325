import React from "react";
import ImageWrapper from "./ImageWrapper";

function CmsImage({ component, page_id = "" }) {
  var componentStyle =
    typeof component.options.image != "undefined"
      ? {
          backgroundImage: `url(${component.options.image.url})`,
        }
      : {};

  return (
    <div className={`h-full image`} id={`${component.id ?? ""}`}>
      <div
        className={`imageContainer`}
        // dangerouslySetInnerHTML={{ __html: component.options.content }}
        // style={componentStyle}
      >
        {component.options.image.url != "" ? (
          <ImageWrapper
            alt=""
            src={component.options.image.url}
            blur={component.options.image.urlp}
            layout="intrinsic"
            width={component.options.image.width}
            height={component.options.image.height}
            // priority={true}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CmsImage;
